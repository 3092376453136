import React, {useState} from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Header from 'components/header';
import Button from 'components/button';
import Layout from 'components/layout';
import Box from 'components/box';
import Heading from 'components/heading';
import Info from 'components/info';
import News from 'components/news';
import Staff from 'components/staff';
import About from 'components/about';
import Footer from 'components/footer';
import IO from 'components/io';
import InfoModal from 'components/modal';
import Schedule from 'components/schedule';

const query = graphql`
  {
    allImageSharp {
      nodes {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
          originalName
        }
      }
    }
    allStaffJson(sort: { fields: id, order: DESC }, limit: 2) {
      nodes {
        icon
        name
        role
        bioShort
        bioFull
      }
    }
    allNewsJson(sort: { fields: id, order: DESC }, limit: 2) {
      nodes {
        date
        title
        text
        icon
      }
    }
  }
`;

const Index = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Layout>
      <InfoModal show={showModal} setShow={setShowModal} images={data} />
      <Header>
        <div>
          <Button scrollTarget="#news">Aktuality</Button>
          <Button scrollTarget="#about">Více o nás</Button>
        </div>
      </Header>
      <Box cols>
        <Info>
          <div id="info">
            <Heading center type="primary">
              Rychlé info
            </Heading>
          </div>
          <dl>
            <dt>Adresa</dt>
            <dd>Legionářská 480, Kostelec na Hané</dd>
            <dd>
              <a
                className="subtle"
                href="https://www.google.com/maps/place/Pediatr+Tenora/@49.5091322,17.0598575,17z/data=!3m1!4b1!4m5!3m4!1s0x47125bf8a7bbd0db:0xb9d6dff1b906f8e8!8m2!3d49.5091287!4d17.0620462"
              >
                Zobrazit mapu →
              </a>
            </dd>
          </dl>
          <dl>
            <dt>Ordinační hodiny</dt>
            <Schedule />
          </dl>
          <dl>
            <dt>Smluvní pojišťovny</dt>
            <dd>VZP, ZPMV, OZP, VoZP, ČPZP</dd>
          </dl>
          <dl>
            <dt>Telefon</dt>
            <dd>
              <a href="tel:+420 731 934 864">+420 731 934 864</a>
            </dd>
          </dl>
          <dl>
            <dt>E-mail</dt>
            <dd>
              <a href="mailto: medkal@seznam.cz">pediatrtenora@seznam.cz</a>
            </dd>
          </dl>
          <p className="subtle">
            Nebereme-li zrovna telefon v době ordinačních hodin, pravděpodobně
            se právě staráme o pacienta. V takovémto případě prosíme zavoltejte
            později. Děkujeme.
          </p>
        </Info>
        <div style={{ flex: '1 1 40%', boxSizing: 'border-box' }}>
          <div id="news">
            <Heading center type="secondary">
              Aktuality
            </Heading>
          </div>
          <News news={data.allNewsJson.nodes} />
        </div>
      </Box>
      <IO>
        {({ isVisible, hasBeenVisible }) => (
          <Box>
            <div id="about">
              <Heading center type="primary">
                Naše ordinace
              </Heading>
            </div>
            <About
              images={data.allImageSharp.nodes}
              visible={{ isVisible, hasBeenVisible }}
            >
              <p>
                Poskytujeme léčebnou a preventivní péči pro děti a dorost včetně
                povinného i nepovinného očkování, odběrů nebo např. CRP testů.
              </p>
              <p>
                Naše ordinace se nachází na ulici Legionářská 480 v Kostelci na
                Hané do 10 min. jízdy autem z Prostějova. Můžete se u nás těšit
                z příjemné a komplexně vybavené ordinace, kterou průběžně
                modernizujeme tak, aby se v ní cítili dobře jak děti, tak jejich
                jejich rodiče a zárověň splňovala veškerá kritéria ambulantní
                zdravotnické péče.
              </p>
              <p>
                V naší ordinaci usilujeme o poskytování co možná nejkvalitněší
                zdravotní péče s přátelským přístupem. Máme rozsáhlé zkušenosti
                s lékařskou péčí o novorozence i starší pacienty do 19 let věku.
                Přijděte se k nám podívat v době ordinačních hodin, nebo nám
                zavolejte či napište.
              </p>
              <p>Těšíme se na vás!</p>
            </About>
          </Box>
        )}
      </IO>
      <Box>
        <Heading center type="secondary">
          Personál
        </Heading>
        <Staff staff={data.allStaffJson.nodes}></Staff>
      </Box>
      <Footer>
        <div className="footer-info">
          <p>
            Provozovatelem ordinace je společnost Pediatr Tenora s.r.o., IČ
            29259967, se sídlem: Legionářská 480, 798 41 Kostelec na Hané,
            zapsaná u KS Brno pod sp.zn.: C 68894/KSBR.
          </p>
        </div>
        {/*  <div className="map">
        <a href={data.staticMap.mapUrl}>
          <Img
            fluid={data.staticMap.childFile.childImageSharp.fluid}
            alt="mapa"
          />
        </a>
      </div> */}
      </Footer>
    </Layout>
  );
};
Index.propTypes = {
  data: PropTypes.object.isRequired,
};

const IndexWithQuery = props => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Index data={data} {...props} />
      </>
    )}
  />
);

export default IndexWithQuery;
